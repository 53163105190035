
img {
  width: 100%;
  height: auto;
  display: block;
}

#container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
}

#header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: 12vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}

#headnots {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: lighter;
  width: 100vw;
  height: 3vh;
  overflow: hidden;
}

#headmenu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 8vh;
  overflow: hidden;
}

#headmenu ul {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./buttonBG.png");
  background-repeat: no-repeat;
  background-position: center center;
  font-size: thin;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 200px;
  height: 50px;
  cursor: pointer;
}

.link:hover {
  animation: headShake;
  animation-duration: 0.5s;
}

.link a {
  color: white;
  text-decoration: none;
}

#content {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  width: 100vw;
  height: 85vh;
}

#right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  overflow: hidden;
  width: 30vw;
}

#left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: auto;
  width: 70vw;
}

#logo {
  position: -webkit-sticky;
  top: 5vh;
  position: sticky;
  margin-bottom: 5vh;
}

#logo:hover {
  animation: headShake;
  animation-duration: 0.5s;
}

#footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 3vh;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: lighter;
  overflow: hidden;
}