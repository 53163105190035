@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    font-family: 'Oswald', sans-serif;
    color: white;
    background-image: url("./background.svg");
    background-size: 100vh auto;
    background-attachment: fixed;
    background-color: #1C2433;
}